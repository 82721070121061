import classNames from 'classnames'
// import useTranslation from 'next-translate/useTranslation'
// import Image from 'next/image'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { createPost, getPosts } from 'services'
import backImage from "../../assetes/images/interier.jpeg"
import blackLogo from "../../assetes/images/logo.svg"
import styles from './style.module.scss'


export function Main() {
  const { t } = useTranslation()
  
  const [active, setActive] = useState(false)
 

  return (
    <main
      id="#home"
      className={classNames(styles.main, {
        [styles.active]: active,
      })}
    >
      <div className={styles.banner}>
        <div className={styles.headerTitle}>
          <div className={styles.title}>
            
            <img width="100%" src={blackLogo} alt="logo" />
          </div>
          <div className={styles.text}> {t("yourfantasystartshere")} </div>
        </div>
        <img
          src={backImage}
          // objectFit="cover"
          // priority={true}
          alt="airplain"
          // layout="fill"
          style={{width: '100%'}}
        />
      </div>

      {/* <Catalog /> */}

      {/* next image domain config
            https://nextjs.org/docs/basic-features/image-optimization#domains
        */}
      {/* <div className={styles.img}>
        <Image
          src='https://test.cdn.rasta.app/rasta/9302ea6c-46d9-4a9c-a9e0-5e9aa9631250'
          width={310}
          height={207}
          alt='airplain'
          objectFit='cover'
          layout='responsive'
        />
      </div> */}
      {/* <Button size='large' color='primary' onClick={addPost}>
        Create post
      </Button> */}
      {/* <div>
        {posts.map((item) => (
          <div key={item.id}>{item.title}</div>
        ))}
      </div> */}
    </main>
  );
}
