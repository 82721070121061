import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Container, FormControl, MenuItem, Select } from '@mui/material'
// import useTranslation from 'next-translate/useTranslation'
// import Image from 'next/image'
// import Link from 'next/link'
// import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import logo from '../../assetes/images/black-logo1.png'
import BurgerMenu from '../BurgerMenu'
import cls from './style.module.scss'

export function Header() {
  
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [langOpen, setLangOpen] = useState(false);
  const location = useLocation()
  const router = location?.pathname
  

  const langs = [
    {
      key: 'ru',
      label: 'ru'
    },
    {
      key: 'uz',
      label: 'uz'
    },
  ]

  const burgerData = [
    {
      id: 1,
      name: t("home"),
      href: "/#home",
    },
    {
      id: 3,
      name: t("catalogs"),
      href: "/#catalog",
    },
    {
      id: 2,
      name: t("about"),
      href: "/#about",
    },
    {
      id: 4,
      name: t("contacts"),
      href: "/contacts",
    },
  ];

  const arrowDown = {
    cursor: "pointer",
    width: "30px",
    marginRight: "10px",
  };
  const arrowUp = {
    cursor: "pointer",
    width: "30px",
    marginRight: "10px",
    transform: "rotate(180deg)",
  };

  useEffect(() => {
    i18n.changeLanguage(language === 0 ? 'ru' : language === 1 ? 'uz' : 'ru');
  }, [language, i18n]);

  // const changeLanguage = (e) => {
  //   console.log(e)
  // }

  return (
    <div
      style={{ background: "#F1EFEF" }}
      className={cls.main_container} 
     
    >
      <Container className={cls.mobile_container}>
        <Box className={cls.header_menu}>
          <div className={cls.logo}>
            <a href={"/"} >
            
              <div className={cls.logoName}>
               <img src={logo} alt='logo' width={100} height={50} />
              </div>
            </a>
          </div>
          <div className={cls.headerMenu_items}>
            <a href='/#home'>
              
              <div className="hoverable_btn">{t("home")}</div>{" "}
            </a>
            <a href='#catalog'>
              <div className="hoverable_btn">{t("catalogs")}</div>
            </a>
            <a href='/#about'>
              {" "}
              <div className="hoverable_btn">{t("about")}</div>{" "}
            </a>
            <a href='#contact'>
              {" "}
              <div className="hoverable_btn">{t("contacts")}</div>{" "}
            </a>
          </div>

          <div className={cls.contact_lang_btns}>
            <div className={cls.contact}>
              <a href="tel:+998999999999">+998 71 205 00 35</a>
            </div>
            <div className={cls.select_language}>
              <FormControl style={{ height: "100%" }}>
                <Select
                  open={langOpen}
                  value={language}
                  onClick={() => setLangOpen(!langOpen)}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={cls.language}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <KeyboardArrowDown
                      style={!langOpen ? arrowDown : arrowUp}
                      onClick={() => setLangOpen(!langOpen)}
                    />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {langs?.map((item, index) => (
                    <MenuItem key={index} value={index}>
                     
                        {item.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <BurgerMenu data={burgerData} className={cls.burger} />
        </Box>
      </Container>

    </div>
  );
}
