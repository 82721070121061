export const catalogs = [
  { id: 0, image: "/images/products/Аризона.jpg", name: "Аризона", desc: "" },
  { id: 1, image: "/images/products/Ясень Бородино.jpg", name: "Ясень Бородино", desc: "" },
  { id: 2, image: "/images/products/back.jpg", name: "Астана пайн", desc: "" },
  { id: 3, image: "/images/products/Баунти.jpg", name: "Баунти", desc: "" },
  { id: 4, image: "/images/products/Бахия палисандр и австралия.jpg", name: "Бахия палисандр и австралия", desc: "" },
  { id: 5, image: "/images/products/береза одесса, липа вегас.jpg", name: "Береза одесса, липа вегас", desc: "" },
  { id: 6, image: "/images/products/Бонито (мрамор).jpg", name: "Бонито (мрамор)", desc: "" },
  { id: 7, image: "/images/products/Белая линия.jpg", name: "Белая линия", desc: "" },
  { id: 8, image: "/images/products/whiteForest.jpg", name: "Белый лес", desc: "" },
  { id: 9, image: "/images/products/Липа Варшава.jpg", name: "Липа Варшава", desc: "" },
  { id: 10, image: "/images/products/Бук Бавария.jpg", name: "Бук Бавария", desc: "" },
  { id: 11, image: "/images/products/Венге.jpg", name: "Венге", desc: "" },
  { id: 12, image: "/images/products/Вишня.jpg", name: "Вишня", desc: "" },
  { id: 13, image: "/images/products/Вяз Китами 02.jpg", name: "Вяз Китами 02", desc: "" },
  { id: 14, image: "/images/products/Гиккори Джексон.jpg", name: "Гиккори Джексон", desc: "" },
  { id: 15, image: "/images/products/GikoriJeksonMedium.jpg", name: "Гикори Джексон средний", desc: "" },
  { id: 16, image: "/images/products/whiteDub.jpg", name: "Дуб белый", desc: "" },
  { id: 17, image: "/images/products/dubbiskayskiy.jpg", name: "Дуб бискайский", desc: "" },
  { id: 18, image: "/images/products/Дуб бонифацио.jpg", name: "Дуб бонифацио", desc: "" },
  { id: 19, image: "/images/products/Дуб Делано 02.jpg", name: "Дуб Делано 02", desc: "" },
  { id: 20, image: "/images/products/Дуб Делано 05.jpg", name: "Дуб Делано 05", desc: "" },
  { id: 21, image: "/images/products/Дуб Кантербери.jpg", name: "Дуб Кантербери", desc: "" },
  { id: 22, image: "/images/products/дуб каньон крофт.jpg", name: "Дуб каньон крофт", desc: "" },
  { id: 23, image: "/images/products/Дуб Каньон Монумент 02.jpg", name: "Дуб Каньон Монумент 02", desc: "" },
  { id: 24, image: "/images/products/ducCanonMonu04.jpg", name: "Дуб Каньон Монумент 04 (темный)", desc: "" },
  { id: 25, image: "/images/products/dubkataniyesvetliy.jpg", name: "Дуб Катания светлый", desc: "" },
  { id: 26, image: "/images/products/дуб катания.jpg", name: "Дуб катания", desc: "" },
  { id: 27, image: "/images/products/Дуб Марбург (Феррара).jpg", name: "Дуб Марбург (Феррара)", desc: "" },
  { id: 28, image: "/images/products/Дуб Маттерхон.jpg", name: "Дуб Маттерхон", desc: "" },
  { id: 29, image: "/images/products/dubMilkDoven.jpg", name: "Дуб молочный, дуб девон", desc: "" },
  { id: 30, image: "/images/products/Дуб Ривьера - 01.jpg", name: "Дуб Ривьера - 01", desc: "" },
  { id: 31, image: "/images/products/Дуб ривьера - 02.jpg", name: "Дуб ривьера - 02", desc: "" },
  { id: 32, image: "/images/products/Дуб санома -1.jpg", name: "Дуб санома -1", desc: "" },
  { id: 33, image: "/images/products/дуб санома -3.jpg", name: "дуб санома -3", desc: "" },
  { id: 34, image: "/images/products/Дуб стерлинг -3.jpg", name: "Дуб стерлинг -3", desc: "" },
  { id: 35, image: "/images/products/Дуб стерлинг -5.jpg", name: "Дуб стерлинг -5", desc: "" },
  { id: 36, image: "/images/products/-Дуб честерфилд-3.jpg", name: "Дуб честерфилд-3", desc: "" },
  { id: 37, image: "/images/products/Дуб Честерфильд 5.jpg", name: "Дуб Честерфильд 5", desc: "" },
  { id: 38, image: "/images/products/Дуб Шамони.jpg", name: "Дуб Шамони", desc: "" },
  { id: 39, image: "/images/products/Дуб Юкон.jpg", name: "Дуб Юкон", desc: "" },
  { id: 40, image: "/images/products/Липа Варшава.jpg", name: "Липа Варшава", desc: "" },
  { id: 41, image: "/images/products/липа светлана 100 , липа догал.jpg", name: "Липа светлана 100 , липа догал", desc: "" },
  { id: 42, image: "/images/products/Лиственница Сибиу.jpg", name: "Лиственница Сибиу", desc: "" },
  { id: 43, image: "/images/products/Манго.jpg", name: "Манго", desc: "" },
  { id: 44, image: "/images/products/Ноче Комо.jpg", name: "Ноче Комо", desc: "" },
  { id: 45, image: "/images/products/ноче мажори.jpg", name: "Ноче мажори", desc: "" },
  { id: 46, image: "/images/products/Ноче Сандрио.jpg", name: "Ноче Сандрио", desc: "" },
  { id: 47, image: "/images/products/Ноче эллето.jpg", name: "Ноче эллето", desc: "" },
  { id: 48, image: "/images/products/Ольха.jpg", name: "Ольха", desc: "" },
  { id: 49, image: "/images/products/Орех Азия, орех дильноза.jpg", name: "Орех Азия, орех дильноза", desc: "" },
  { id: 50, image: "/images/products/buxaraItalyOrex.jpg", name: "Орех бухара, итальянский орех", desc: "" },
  { id: 51, image: "/images/products/Орех Колумбия.jpg", name: "Орех Колумбия", desc: "" },
  { id: 52, image: "/images/products/Орех лион -  02.jpg", name: "Орех лион -  02", desc: "" },
  { id: 53, image: "/images/products/орех лион 04.jpg", name: "Орех лион 04", desc: "" },
  { id: 54, image: "/images/products/Орех Станбул.jpg", name: "Орех Станбул", desc: "" },
  { id: 55, image: "/images/products/Орех Тянь Шань.jpg", name: "Орех Тянь Шань", desc: "" },
  { id: 56, image: "/images/products/Орех Чавенна.jpg", name: "Орех Чавенна", desc: "" },
  { id: 57, image: "/images/products/Орех Шоколад.jpg", name: "Орех Шоколад", desc: "" },
  { id: 58, image: "/images/products/Панареа.jpg", name: "Панареа", desc: "" },
  { id: 59, image: "/images/products/Пикард.JPG", name: "Пикард", desc: "" },
  { id: 60, image: "/images/products/Платинум.JPG", name: "Платинум", desc: "" },
  { id: 61, image: "/images/products/PolisonBayya.jpg", name: "Полисандр Байя", desc: "" },
  { id: 62, image: "/images/products/Ровере Кендал.JPG", name: "Ровере Кендал", desc: "" },
  { id: 63, image: "/images/products/Rusik.JPG", name: "Рустико Персиполь (Лайт Антик)", desc: "" },
  { id: 64, image: "/images/products/Роял..JPG", name: "Роял", desc: "" },
  { id: 65, image: "/images/products/salad.JPG", name: "Салатовый", desc: "" },
  { id: 66, image: "/images/products/Санта Мария.jpg", name: "Санта Мария", desc: "" },
  { id: 67, image: "/images/products/lightGrey.JPG", name: "Светло серый", desc: "" },
  { id: 68, image: "/images/products/lightBlue.jpg", name: "Светло синий", desc: "" },
  { id: 69, image: "/images/products/kaspiGray2.jpg", name: "Серый Каспийский (2)", desc: "" },
  { id: 70, image: "/images/products/kaspiGray.jpg", name: "Серый Каспийский", desc: "" },
  { id: 71, image: "/images/products/Сосна Крем.jpg", name: "Сосна Крем", desc: "" },
  { id: 72, image: "/images/products/Сосна родос.jpg", name: "Сосна родос", desc: "" },
  { id: 73, image: "/images/products/Сосна санторини 02.JPG", name: "Сосна санторини 02", desc: "" },
  { id: 74, image: "/images/products/Сосна Светлая.jpg", name: "Сосна Светлая", desc: "" },
  { id: 75, image: "/images/products/Сосна Фрост  03.jpg", name: "Сосна Фрост  03", desc: "" },
  { id: 76, image: "/images/products/Сосна фрост 01.jpg", name: "Сосна фрост 01", desc: "" },
  { id: 77, image: "/images/products/софия.jpg", name: "София", desc: "" },
  { id: 78, image: "/images/products/Твист 235.JPG", name: "Твист 235", desc: "" },
  { id: 79, image: "/images/products/Фило.JPG", name: "Фило", desc: "" },
  { id: 80, image: "/images/products/флоренция.jpg", name: "флоренция", desc: "" },
  { id: 81, image: "/images/products/fonBlue.JPG", name: "Фон Голубой", desc: "" },
  { id: 82, image: "/images/products/fonYellow.jpg", name: "Фон желтый", desc: "" },
  { id: 83, image: "/images/products/fonRed.jpg", name: "Фон красный", desc: "" },
];



