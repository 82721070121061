import { About } from "./About/About"
import { Catalog } from "./Catalogs/Catalog"
import { Main } from "./Main/Main"
import { OurBenefits } from "./OurBenefits/Benefits"





const MainComponent = () => {

    
    return(
        <>
        <Main />
        <Catalog />
        <About />
        <OurBenefits />
        </>
    )
}



export default MainComponent