import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainComponent from './components';
import CatalogLists from './components/Catalogs/CatalogLists';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import './styles/global.scss'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback="loading">
              <MainComponent />
            </Suspense>
          }
        />
        <Route
          path="/catalogs"
          element={
            <Suspense fallback="loading">
              <CatalogLists />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
