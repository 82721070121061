import i18next from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import uzLang from './uz.json'
import urLang from './ru.json'


const resources = {
  uz: {
    translation: uzLang,
  },
  ru: {
    translation: urLang,
  },
 
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:"ru", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
